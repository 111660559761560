$cart: "\e900";
$close: "\e901";
$confiance: "\e902";
$design: "\e903";
$fb: "\e904";
$houzz: "\e905";
$instagram: "\e906";
$installation: "\e907";
$linkedin: "\e908";
$long-arrow-right: "\e909";
$made-in: "\e90a";
$measure: "\e90b";
$pinterest: "\e90c";
$profil:  "\e90d";
$reward: "\e90e";
$search: "\e90f";
$youtube: "\e910";
$icon-ange: "\e911";
$icon-assistance: "\e912";
$icon-izi: "\e913";
$icon-safety: "\e914";
$icon-simple-arrow-down: "\e915";
$arrow-down: "\e916";
$icon-appelez: "\e917";
$icon-clavardez: "\e918";
$icon-crivez: "\e919";
$icon-location: "\e91a";
$icon-heart: "\e91b";
$icon-share: "\e91c";


.icon-cart:before {
    content: "\e900";
}
.icon-close:before {
    content: "\e901";
}
.icon-confiance:before {
    content: "\e902";
}
.icon-design:before {
    content: "\e903";
}
.icon-fb:before {
    content: "\e904";
}
.icon-houzz:before {
    content: "\e905";
}
.icon-instagram:before {
    content: "\e906";
}
.icon-installation:before {
    content: "\e907";
}
.icon-linkedin:before {
    content: "\e908";
}
.icon-long-arrow-right:before {
    content: "\e909";
}
.icon-made-in:before {
    content: "\e90a";
}
.icon-measure:before {
    content: "\e90b";
}
.icon-pinterest:before {
    content: "\e90c";
}
.icon-profil:before {
    content: "\e90d";
}
.icon-reward:before {
    content: "\e90e";
}
.icon-search:before {
    content: "\e90f";
}
.icon-youtube:before {
    content: "\e910";
}
.icon-ange:before {
    content: "\e911";
}
.icon-assistance:before {
    content: "\e912";
}
.icon-izi:before {
    content: "\e913";
}
.icon-safety:before {
    content: "\e914";
}
.icon-simple-arrow-down:before {
    content: "\e915";
}
.icon-arrow-down:before {
    content: "\e916";
}
.icon-appelez:before {
    content: "\e917";
}
.icon-clavardez:before {
    content: "\e918";
}
.icon-crivez:before {
    content: "\e919";
}
.icon-location:before {
    content: "\e91a";
}
.icon-heart:before {
    content: "\e91b";
}
.icon-share:before {
    content: "\e91c";
}
